<template>
  
  
  <div id="app">
    <div id="logo">
    <img alt="Vue logo" src="./assets/logo.png" height="100px" width="250px" style="float:left">
    <br style="clear: both;">

  </div>
  <div>
    
  </div>
   <div>
    <h2 style="color:#a51c30;">SCIENTIFIC INSTRUMENT SHOP</h2>
    <h3 style="color:#a51c30;">GANTT CHART DEMO - Version 1 (scroll down for Version 2)</h3>
    <HelloWorld style="margin-top:5%"/>

   </div>
   <div>
    <h3 style="color:#a51c30; margin-top:5%">GANTT CHART DEMO - Version 2</h3>
    <GanttTwo style="margin-top:5%"/>

   </div>

  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import GanttTwo from './components/GanttTwo.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    GanttTwo

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 40px;
}
</style>
