<template>
  <div>
    <apexchart
      type="rangeBar"
      height="700"
      :options="chartOptions"
      :series="chartData"
      title="Scientific Parts Shop"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "rangeBar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            datetimeFormatter: {
              day: 'dd MMM, dddd',
          },
          
          }
     
        },
        legend: {
          position: "bottom",
        },
        grid: {
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: false
                }
              },
        
            },
      
      },
      chartData: [],
    };
  },
  methods: {
    generateMockData() {
      this.chartData = [
        {
          name: "Task: Ordering the Part",
          data: [
            {
              x: "T D",
              y: [
                new Date("2023-09-01").getTime(),
                new Date("2023-09-03").getTime(),
              ],
            },
            {
              x: "M D",
              y: [
                new Date("2023-09-20").getTime(),
                new Date("2023-09-21").getTime(),
              ],
            },
            {
              x: "O O",
              y: [
                new Date("2023-09-10").getTime(),
                new Date("2023-09-13").getTime(),
              ],
            },
            {
              x: "B T",
              y: [
                new Date("2023-09-10").getTime(),
                new Date("2023-09-12").getTime(),
              ],
            },
          ],
        },
        {
          name: "Task: Design",
          data: [
            {
              x: "T D",
              y: [
                new Date("2023-09-01").getTime(),
                new Date("2023-09-03").getTime(),
              ],
            },
            {
              x: "M D",
              y: [
                new Date("2023-09-20").getTime(),
                new Date("2023-09-22").getTime(),
              ],
            },
            {
              x: "O O",
              y: [
                new Date("2023-09-10").getTime(),
                new Date("2023-09-13").getTime(),
              ],
            },
            {
              x: "B T",
              y: [
                new Date("2023-09-10").getTime(),
                new Date("2023-09-12").getTime(),
              ],
            },
          ],
        },

        {
          name: "Task: Cutting the Part",
          data: [
            {
              x: "T D",
              y: [
              new Date("2023-09-03").getTime(),
          new Date("2023-09-06").getTime(),
              ],
            },
            {
              x: "M D",
              y: [
              new Date("2023-09-22").getTime(),
              new Date("2023-09-24").getTime(),
              ],
            },
            {
              x: "O O",
              y: [
              new Date("2023-09-13").getTime(),
          new Date("2023-09-15").getTime(),
              ],
            },
            {
              x: "B T",
              y: [
              new Date("2023-09-12").getTime(),
          new Date("2023-09-13").getTime(),
              ],
            },
          ],
        },

        {
          name: "Task: Processing the Part",
          data: [
            {
              x: "T D",
              y: [
              new Date("2023-09-06").getTime(),
          new Date("2023-09-09").getTime(),
              ],
            },
            {
              x: "M D",
              y: [
              new Date("2023-09-24").getTime(),
          new Date("2023-09-26").getTime(),
              ],
            },
            {
              x: "O O",
              y: [
              new Date("2023-09-15").getTime(),
          new Date("2023-09-18").getTime(),
              ],
            },
            {
              x: "B T",
              y: [
              new Date("2023-09-13").getTime(),
          new Date("2023-09-14").getTime(),
              ],
            },
          ],
        },

        {
          name: "Task: Shipment",
          data: [
            {
              x: "T D",
              y: [
              new Date("2023-09-09").getTime(),
          new Date("2023-09-10").getTime(),
              ],
            },
            {
              x: "M D",
              y: [
              new Date("2023-09-26").getTime(),
              new Date("2023-09-27").getTime(),
              ],
            },
            {
              x: "O O",
              y: [
              new Date("2023-09-18").getTime(),
          new Date("2023-09-20").getTime(),
              ],
            },
            {
              x: "B T",
              y: [
              new Date("2023-09-14").getTime(),
          new Date("2023-09-15").getTime(),
              ],
            },
          ],
        },

        {
          name: "Task: Survey",
          data: [

            {
              x: "M D",
              y: [
              new Date("2023-09-29").getTime(),
          new Date("2023-09-30").getTime(),
              ],
            },
          ],
        },

        // Add more tasks and projects here
      ];
    },
  },
  created() {
    this.generateMockData();
  },
};
</script>

<style scoped>
.gantt-chart {
  /* Increase the bar height as needed */
  .apexcharts-rangebar {
    height: 40px; /* Adjust the height to your preference */
  }
}
</style>