<template>
  <div>
    <apexchart
      type="rangeBar"
      height="700"
      :options="chartOptions"
      :series="chartData"
      title="Scientific Parts Shop"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "rangeBar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: "datetime",

          labels: {
            datetimeFormatter: {
              day: 'dd MMM, dddd',
          },

          }
         
          
        },

        legend: {
          position: "bottom",
        },
        grid: {
              xaxis: {
                lines: {
                  show: true
                }
              },
              yaxis: {
                lines: {
                  show: false
                }
              },
       
            }
  
      },
      chartData: [],
    };
  },
  methods: {
    generateMockData() {
      this.chartData = [
  {
    name: "M D",
    data: [
      {
        x: "Ordering the Part",
        y: [
          new Date("2023-09-20").getTime(),
          new Date("2023-09-21").getTime(),
        ],
      },
      {
        x: "Design",
        y: [
          new Date("2023-09-20").getTime(),
          new Date("2023-09-21").getTime(),
        ],
      },
      {
        x: "Cutting the Part",
        y: [
          new Date("2023-09-22").getTime(),
          new Date("2023-09-23").getTime(),
        ],
      },
      {
        x: "Processing the Part",
        y: [
          new Date("2023-09-24").getTime(),
          new Date("2023-09-25").getTime(),
        ],
      },
      {
        x: "Shipment",
        y: [
          new Date("2023-09-26").getTime(),
          new Date("2023-09-27").getTime(),
        ],
      },
      {
        x: "Survey",
        y: [
          new Date("2023-09-29").getTime(),
          new Date("2023-09-30").getTime(),
        ],
      },
    ],
  },
  {
    name: "T D",
    data: [
      {
        x: "Ordering the Part",
        y: [
          new Date("2023-09-01").getTime(),
          new Date("2023-09-03").getTime(),
        ],
      },
      {
        x: "Design",
        y: [
          new Date("2023-09-01").getTime(),
          new Date("2023-09-03").getTime(),
        ],
      },
      {
        x: "Cutting the Part",
        y: [
          new Date("2023-09-03").getTime(),
          new Date("2023-09-06").getTime(),
        ],
      },
      {
        x: "Processing the Part",
        y: [
          new Date("2023-09-06").getTime(),
          new Date("2023-09-09").getTime(),
        ],
      },
      {
        x: "Shipment",
        y: [
          new Date("2023-09-09").getTime(),
          new Date("2023-09-10").getTime(),
        ],
      },
    ],
  },
  {
    name: "O O",
    data: [
      {
        x: "Ordering the Part",
        y: [
          new Date("2023-09-10").getTime(),
          new Date("2023-09-12").getTime(),
        ],
      },
      {
        x: "Design",
        y: [
          new Date("2023-09-10").getTime(),
          new Date("2023-09-12").getTime(),
        ],
      },
      {
        x: "Cutting the Part",
        y: [
          new Date("2023-09-13").getTime(),
          new Date("2023-09-15").getTime(),
        ],
      },
      {
        x: "Processing the Part",
        y: [
          new Date("2023-09-14").getTime(),
          new Date("2023-09-18").getTime(),
        ],
      },
      {
        x: "Shipment",
        y: [
          new Date("2023-09-18").getTime(),
          new Date("2023-09-20").getTime(),
        ],
      },
    ],
  },
  {
    name: "B T",
    data: [
      {
        x: "Ordering the Part",
        y: [
          new Date("2023-09-10").getTime(),
          new Date("2023-09-11").getTime(),
        ],
      },
      {
        x: "Design",
        y: [
          new Date("2023-09-10").getTime(),
          new Date("2023-09-11").getTime(),
        ],
      },
      {
        x: "Cutting the Part",
        y: [
          new Date("2023-09-12").getTime(),
          new Date("2023-09-13").getTime(),
        ],
      },
      {
        x: "Processing the Part",
        y: [
          new Date("2023-09-13").getTime(),
          new Date("2023-09-14").getTime(),
        ],
      },
      {
        x: "Shipment",
        y: [
          new Date("2023-09-14").getTime(),
          new Date("2023-09-15").getTime(),
        ],
      },
    ],
  },
  // Add more projects and their subtasks here
];
    },
  },
  created() {
    this.generateMockData();
  },
};
</script>

<style scoped>
.gantt-chart {
  /* Increase the bar height as needed */
  .apexcharts-rangebar {
    height: 40px; /* Adjust the height to your preference */
  }
}
</style>